import React from 'react';
import { Layout, Menu, Breadcrumb, Result, Button, theme } from 'antd';
import { LaptopOutlined, BranchesOutlined, GroupOutlined, UserOutlined } from '@ant-design/icons';
import { Link, Routes, Route, BrowserRouter as Router, useLocation } from "react-router-dom";

import Apps2 from './Apps2';
import AppHome from './AppHome';
import Devices from './Devices';
import Certs from './Cert';
import Oplog from './Oplog';
import Skad from './Skad';
import Data from './Data';
import Mapps from './Mapps';
import Mapp from './Mapp';
import Config from './Config';
import GameVersion from './GameVersion';
import ConfigRes from './ConfigRes';
import ConfigAB from './ConfigAB';
import ConfigTreasure from './ConfigTreasure';
import EventDef from './EventDef';
import EventPlan from './EventPlan';
import Acquire from './Acquire';
import { Use404 } from "./Use"

const { Header, Content, Footer, Sider } = Layout;

const P404 = () => {
    return <Result
        status="404"
        title="404"
        subTitle="抱歉，这个页面暂时不存在。"
        extra={<Button type="primary"><Link to={`/modp`} >Back Home</Link></Button>}
    />
}

const modpReg = new RegExp(`^/modp(/.*)?$`);
const certsReg = new RegExp(`^/modp/certs(/.*)?$`);
const oplogReg = new RegExp(`^/modp/oplog(/.*)?$`);
const skadReg = new RegExp(`^/modp/skad(/.*)?$`);

const MenuTop = () => {
    const location = useLocation();
    let keys = [];
    if (modpReg.test(location.pathname)) {
        if (skadReg.test(location.pathname)) {
            keys = ['skad'];
        } else if (certsReg.test(location.pathname)) {
            keys = ['certs']
        } else if (oplogReg.test(location.pathname)) {
            keys = ['oplog']
        } else {
            keys = ['modp'];
        }
    }
    const items = [
        {
            key: "modp",
            label: <Link to={`/modp`} >home</Link>
        }
        , {
            key: `certs`,
            label: <Link to={`/modp/certs`} >certs</Link>,
        }
        , {
            key: `oplog`,
            label: <Link to={`/modp/oplog`} >oplog</Link>,
        }
        , {
            key: `skad`,
            label: <Link to={`/modp/skad`} >skad</Link>,
        }
        // , {
        //     key: `ac`,
        //     label: <Link to={`/modp/ac`} >表现</Link>,
        // }
    ];
    return <Menu
        theme="dark"
        mode="horizontal "
        selectedKeys={keys}
        items={items}
    />
}

const MenuLeft = ({ colorBgContainer }) => {
    const location = useLocation();
    const p404 = Use404();
    if (p404) {
        return
    }
    const pathSnippets = location.pathname.split('/').filter((i) => i); //remove any empty strings
    let app = pathSnippets[1];
    const items = [
        {
            key: `data`,
            icon: React.createElement(LaptopOutlined),
            label: <Link to={`/modp/${app}/data`} >设备数据</Link>,
        }
        , {
            key: `txt`,
            icon: React.createElement(BranchesOutlined),
            label: <Link to={`/modp/${app}/txt`} >配置版本</Link>,
        }
        , {
            key: `res`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/res`} >资源版本</Link>,
        }
        , {
            key: `ab`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/ab`} >用户类型</Link>,
        }
        , {
            key: `event/plan`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/event/plan`} >活动计划</Link>,
        }
        , {
            key: `event/def`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/event/def`} >活动值表</Link>,
        }
        , {
            key: `version`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/version`} >新资源</Link>,
        }
        , {
            key: `treasure`,
            icon: React.createElement(GroupOutlined),
            label: <Link to={`/modp/${app}/treasure`} >工会活动</Link>,
        }
        , {
            key: `devices`,
            icon: React.createElement(UserOutlined),
            label: <Link to={`/modp/${app}/devices`} >设备管理</Link>,
        }
    ];
    // if (/^i.*/.test(app)) {
    //     items.push({
    //         key: `skad`,
    //         icon: React.createElement(ScheduleOutlined), 
    //         label: <Link to={`/modp/${app}/skad`} >转化值</Link>,
    //     })
    // }
    let keys = [];
    if (pathSnippets.length >= 3) {
        items.forEach(el => {
            if (location.pathname.endsWith(el.key)) {
                keys.push(el.key)
            }
        });
    }

    return <Sider style={{
        background: colorBgContainer,
    }}
        breakpoint="lg"
        collapsedWidth="0"
    >
        <Menu
            mode="inline"
            items={items}
            selectedKeys={keys}
        />
    </Sider>
}

const CustomeBreadcrumb = () => {
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
        return { title: <Link to={url}>{pathSnippets[index]}</Link> };
    });
    const breadcrumbItems = [].concat(extraBreadcrumbItems);
    return <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
}

export default function Main() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Router>
            <Layout style={{
                minHeight: '100vh',
            }}>
                <Header>
                    <MenuTop />
                </Header>

                <Content className='ct-padding'>
                    <CustomeBreadcrumb />
                    <Layout
                        style={{
                            padding: '24px 0',
                            background: colorBgContainer,
                        }}
                    >
                        <MenuLeft colorBgContainer={colorBgContainer} />
                        <Content
                            style={{
                                padding: '0 24px',
                            }}
                        >
                            <Routes>
                                <Route path={`/modp`} element={<Apps2 />} />

                                <Route path={`/modp/:app/devices`} element={<Devices />} />

                                <Route path={`/modp/mapps`} element={<Mapps />} />
                                <Route path={`/modp/mapps/:id`} element={<Mapp />} />

                                <Route path={`/modp/:app/skad`} element={<Skad />} />
                                <Route path={`/modp/:app/`} element={<AppHome />} />
                                <Route path={`/modp/:app/data`} element={<Data />} />
                                <Route path={`/modp/:app/txt`} element={<Config />} />
                                <Route path={`/modp/:app/version`} element={<GameVersion />} />
                                <Route path={`/modp/:app/res`} element={<ConfigRes />} />
                                <Route path={`/modp/:app/ab`} element={<ConfigAB />} />
                                <Route path={`/modp/:app/treasure`} element={<ConfigTreasure />} />
                                <Route path={`/modp/:app/event/def`} element={<EventDef />} />
                                <Route path={`/modp/:app/event/plan`} element={<EventPlan />} />


                                <Route path={`/modp/ip`} element={<></>} />
                                <Route path={`/modp/ac`} element={<Acquire />} />
                                <Route path={`/modp/skad`} element={<Skad />} />
                                <Route path={`/modp/certs`} element={<Certs />} />
                                <Route path={`/modp/oplog`} element={<Oplog />} />

                                <Route path="*" element={<P404 />} />
                            </Routes>

                        </Content>
                    </Layout>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    @{process.env.REACT_APP_GIT_REVISION}
                </Footer>
            </Layout>
        </Router>
    )
};
