import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Table, Modal, Popconfirm, Tooltip, Switch, InputNumber, Space, Tag } from 'antd';
import { useParams } from "react-router-dom";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueProvider';

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={24}>
            <Col xs={24} xl={16}>
            </Col>
            <Col xs={18} xl={6} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => {
                    formSearch.submit()
                }}>
                    刷新
                </Button>
            </Col>
            <Col xs={6} xl={2} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={clickAdd}>
                    添加版本
                </Button>
            </Col>
        </Row>
        <Row>
            <Col >
                <br></br>
            </Col>
        </Row>
    </Form>
};

export default function ConfigAB() {

    let { app } = useParams();
    const { token, makeRequest } = useSharedValues()
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const p404 = Use404();
    const [connected, setConnected] = useState(false);
    const [cpt, setCpt] = useState('');
    const [formAdd] = Form.useForm();
    const [open, setOpen] = useState(false);

    const queryList = useCallback((values, connected) => {
        setLd(true);
        if (connected) {
            const okfn = (d) => {
                setDs(d);
                setConnected(false);
            }
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab`, values, okfn, setLd)
        } else {
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab`, values, setDs, setLd)
        }
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `ConfigAB`;
            queryList({}, false);
        }
    }, [p404, queryList]);

    useEffect(() => {
        if (!connected) {
            const r = ds.find(r => r.pending || (r.status && r.status === 7));
            if (r) {
                setConnected(true);
                var eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab/sse/${r.id}?Auth=${token}`);
                eventSource.onopen = function () {
                    console.log('sse connected');
                };
                eventSource.onmessage = function (event) {
                    eventSource.close();
                    queryList({}, true)
                };
                eventSource.onerror = function () {
                    console.log('sse closed')
                    eventSource.close();
                    setConnected(false)
                };
            }
        }
    }, [ds])

    if (p404) {
        return p404;
    }
    const clickAdd = () => {
        formAdd.setFieldsValue({
            types: []
        })
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const createVer = (values) => {
        let types = values["types"];
        let doc = {};
        for (const type of types) {
            if (type.Prob > 0) {
                if (type.Redo) {
                    type['Uid'] = crypto.randomUUID();
                } else {
                    type.Redo = false
                }
                doc[type.N.toString()] = type
            } else {
                doc[type.N.toString()] = null
            }
        }
        let body = { "doc": JSON.stringify(doc) };
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab`, body, okfn, setLd);
    };

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab/${id}`, null, delOk, setLd)
    }
    const pubfn = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/ab/${r.id}`, r, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 65,
        },
        {
            title: '更新内容',
            width: 180,
            dataIndex: 'doc',
            render: (_, r) => {
                let doc = JSON.parse(r.doc);
                const d = [];
                for (var key in doc) {
                    if (doc[key]) {
                        d.push(key + "->" + doc[key]["Prob"] + "% " + (doc[key]["Redo"] ? "redo" : ""))
                    } else {
                        d.push(key + "->null")
                    }
                }
                return d.join(", ")
            }
        },
        {
            title: '更新后',
            width: 180,
            render: (_, r) => {
                let doc = JSON.parse(r.doc_revised);
                const d = [];
                for (var key in doc) {
                    d.push(key + "->" + doc[key]["Prob"] + "%" + (doc[key]["Redo"] ? " redo" : ""))
                }
                return d.join(", ")
            }
        },
        {
            title: '创建时间',
            width: 128,
            dataIndex: 'create_time',
        },
        {
            title: '发布时间',
            width: 128,
            render: (_, r) => {
                if (r.status === 5 && r.estimated_time) {
                    return <Space direction="vertical" style={{ textAlign: 'left' }}>
                        <span>🕔</span>
                        <span>{r.estimated_time}</span>
                    </Space>;
                } else if (r.release_time) {
                    return r.release_time
                }
            }
        },
        {
            title: '状态',
            width: 98,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0:
                        return <Tag icon={<InfoCircleOutlined />} color="default">新版本</Tag>
                    case 1:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                        }
                        return <Tag icon={<CheckCircleOutlined />} color="success">使用中</Tag>
                    case 2:
                        return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                    case 3:
                        return <Tag icon={<MinusCircleOutlined />} color="default">历史</Tag>
                    case 4:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">未使用</Tag>
                    case 5:
                        return <Tag icon={<ClockCircleOutlined />} color="default">定时发布</Tag>
                    case 6:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">定时发布取消</Tag>
                    case 7:
                        return <Tag icon={<SyncOutlined spin />} color="processing">处理中</Tag>
                    case 8:
                        return <Tag icon={<ExclamationCircleOutlined />} color="error">处理错误</Tag>
                    default:
                        return <>未知状态</>
                }
            }
        },
        {
            title: '操作',
            width: 224,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0: //新建
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要发布此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => pubfn(r)}
                                >
                                    <Button type="dashed">
                                        发布
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要删除此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => delfn(r.id)}
                                >

                                    <Button type="dashed">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 1: //发布中
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    case 3: //历史发布
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    default:
                        return <></>
                }
            },
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} />

            <Modal
                title="类型比例"
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => {
                    if (formAdd.getFieldValue("types").length > 0) {
                        formAdd.submit()
                    }
                }}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={createVer}
                >
                    <Form.List name="types">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginTop: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'N']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '缺少类型值',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                addonBefore="类型"
                                                placeholder='1-31的整数'
                                                min="1"
                                                max="31"
                                                precision={0}
                                                step="1"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'Prob']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '缺少概率',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                addonAfter="%"
                                                placeholder='0-100的整数'
                                                min="0"
                                                max="100"
                                                precision={0}
                                                step="1"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'Redo']}
                                        >
                                            <Switch checkedChildren="redo" unCheckedChildren="no" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加类型
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '68vh' }}
                pagination={false}
                bordered={true}
            />
        </>
    );
}
