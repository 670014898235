import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Tag, Popconfirm, Tooltip, Radio, DatePicker, Space, Spin } from 'antd';
import { useParams } from "react-router-dom";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueProvider';

const { TextArea } = Input;

const SearchForm = ({ queryList, clickAdd }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={24}>
            <Col xs={14} sm={4} md={6} lg={8} xl={6}>
                <Form.Item name="app_version">
                    <Input placeholder="版本" />
                </Form.Item>
            </Col>
            <Col xs={10} sm={4} md={6} lg={8} xl={2}>
                <Form.Item name="status">
                    <Radio.Group>
                        <Radio value='1'>使用中</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button type="primary" htmlType="submit">搜索</Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button onClick={() => {
                    formSearch.resetFields();
                }}
                >
                    清空
                </Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={12} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={clickAdd}>
                    添加版本
                </Button>
            </Col>
        </Row>
        {/* <Row>
                <Col >
                    版本单调递增，W：新建未发布， √：正在使用， L：历史发布， N：历史未发布，C：定时发布，NC：定时发布取消，O：处理中，OE：处理失败
                </Col>
            </Row> */}
    </Form>
};

//今天及以后
const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
};
export default function ConfigRes() {

    let { app } = useParams();
    const [ds, setDs] = useState([])
    const [ld, setLd] = useState(false)
    const [open, setOpen] = useState(false);
    const [cpt, setCpt] = useState('');
    const p404 = Use404();

    const { token, makeRequest } = useSharedValues()

    const [connected, setConnected] = useState(false);

    const [formAdd] = Form.useForm();

    const updateRow = (id, status) => {
        const updatedDataSource = [...ds]; // 创建一个新的数据源副本  
        const index = updatedDataSource.findIndex(item => item.id === id);
        if (index !== -1) {
            updatedDataSource[index] = { ...updatedDataSource[index], release_status: status }; // 更新找到的行数据  
        }
        setDs(updatedDataSource); // 设置更新后的数据源到状态变量  
    };

    const queryList = useCallback((values, connected) => {
        if (values['status'] === '1') {
            values['status'] = 1
        }
        setLd(true);
        if (connected) {
            const okfn = (d) => {
                setDs(d);
                setConnected(false);
            }
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res`, values, okfn, setLd)
        } else {
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res`, values, setDs, setLd)
        }
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `ConfigRes`;
            queryList({}, false);
        }
    }, [p404, queryList]);

    useEffect(() => {
        if (!connected) {
            const r = ds.find(r => r.pending || (r.status && r.status === 7));
            if (r) {
                setConnected(true);
                var eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res/sse/${r.id}?Auth=${token}`);
                eventSource.onopen = function () {
                    console.log('sse connected');
                };
                eventSource.onmessage = function (event) {
                    eventSource.close();
                    queryList({}, true);
                };
                eventSource.onerror = function () {
                    console.log('sse closed')
                    eventSource.close();
                    setConnected(false)
                };
            }
        }
    }, [ds])

    if (p404) {
        return p404;
    }

    const clickAdd = () => {
        formAdd.setFieldsValue({
            app_version: '',
            cdn: '',
            estimated_time: null,
            // doc: JSON.stringify(JSON.parse('{"Beta":null, "BetaRevision":null, "Ga":null, "GaRevision":null}'), null, 4)
            doc: JSON.stringify(JSON.parse('{"BetaRevision":null, "GaRevision":null}'), null, 4)
        })
        setOpen(true);
    }

    const okfn = () => {
        setOpen(false);
        queryList({})
    }

    const createVer = (values) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res`, values, okfn, setLd);
    };

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res/${id}`, null, delOk, setLd)
    }
    const pubfn = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res/${r.id}`, r, okfn, setLd)
    }
    const redofn = (r) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res/redo/${r.id}`, r, okfn, setLd)
    }
    const cancelfun = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/config/res/cancel/${r.id}`, r, okfn, setLd)
    }

    const view = (doc_revised) => {
        var newWindow = window.open('', '_blank', 'popup');
        var newWindowDocument = newWindow.document;
        newWindowDocument.write('<pre>' + JSON.stringify(JSON.parse(doc_revised), null, 4) + '</pre>');
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 56,
        },
        {
            title: '版本',
            width: 52,
            dataIndex: 'app_version',
        },
        {
            title: 'CDN',
            width: 52,
            dataIndex: 'cdn',
        },
        {
            title: '更新内容',
            // dataIndex: 'doc',
            width: 132,
            render: (_, r) => {
                let doc = JSON.parse(r.doc);
                let p = '';
                if (doc.GaRevision !== undefined && doc.GaRevision !== null) {
                    p += `Ga -> ${doc.GaRevision}`;
                } else if (doc.GaRevision === null) {
                    p += `Ga -> null`;
                }

                if (doc.BetaRevision !== undefined && doc.BetaRevision !== null) {
                    p += p ? `， Beta -> ${doc.BetaRevision}` : `Beta -> ${doc.BetaRevision}`;
                } else if (doc.BetaRevision === null) {
                    p += p ? `， Beta -> null` : `Beta -> null`;
                }
                return <>{p}</>
            }
        },
        {
            title: '更新后',
            width: 132,
            // dataIndex: 'doc_revised',
            render: (_, r) => {
                let doc = JSON.parse(r.doc_revised);
                let p = '';
                if (doc.GaRevision !== undefined && doc.GaRevision !== null) {
                    p += `Ga -> ${doc.GaRevision}`;
                } else if (doc.GaRevision === null) {
                    p += `Ga -> null`;
                }
                if (doc.BetaRevision !== undefined && doc.BetaRevision !== null) {
                    p += p ? `， Beta -> ${doc.BetaRevision}` : `Beta -> ${doc.BetaRevision}`;
                } else if (doc.BetaRevision === null) {
                    p += p ? `， Beta -> null` : `Beta -> null`;
                }
                return <>{p}</>
            }
        },
        {
            title: '创建时间',
            width: 128,
            dataIndex: 'create_time',
        },
        {
            title: '发布时间',
            width: 128,
            render: (_, r) => {
                if (r.status === 5 && r.estimated_time) {
                    return <Space direction="vertical" style={{ textAlign: 'left' }}>
                        <span>🕔</span>
                        <span>{r.estimated_time}</span>
                    </Space>;
                } else if (r.release_time) {
                    return r.release_time
                }
            }
        },
        {
            title: '状态',
            width: 108,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0:
                        return <Tag icon={<InfoCircleOutlined />} color="default">新版本</Tag>
                    case 1:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                        }
                        return <Tag icon={<CheckCircleOutlined />} color="success">使用中</Tag>
                    case 2:
                        return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                    case 3:
                        return <Tag icon={<MinusCircleOutlined />} color="default">历史</Tag>
                    case 4:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">未使用</Tag>
                    case 5:
                        return <Tag icon={<ClockCircleOutlined />} color="default">定时发布</Tag>
                    case 6:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">定时发布取消</Tag>
                    case 7:
                        return <Tag icon={<SyncOutlined spin />} color="processing">处理中</Tag>
                    case 8:
                        return <Tag icon={<ExclamationCircleOutlined />} color="error">处理错误</Tag>
                    default:
                        return <>未知状态</>
                }
            }
        },
        {
            title: '操作',
            width: 216,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0: //新建
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要发布此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => pubfn(r)}
                                >
                                    <Button type="dashed">
                                        发布
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要删除此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => delfn(r.id)}
                                >

                                    <Button type="dashed">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 1: //发布中
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    case 3: //历史发布
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Button type="dashed" onClick={() => view(r.doc_revised)}>
                                    详细
                                </Button>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    case 5: //定时发布
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要取消吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => cancelfun(r)}
                                >
                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 8: //处理错误
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要重试吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => redofn(r)}
                                >
                                    <Button type="dashed">
                                        重试
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    default:
                        return <Row gutter={8} justify="start">
                        </Row>
                }
            },
        },
    ]

    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} />

            <Modal
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => formAdd.submit()}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={createVer}
                >
                    <Form.Item
                        name="app_version"
                        label="版本"
                        rules={[
                            {
                                required: true,
                                message: '请输入版本',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="doc"
                        label="内容"
                        rules={[
                            {
                                required: true,
                                message: '请输入更细内容',
                            },
                        ]}>
                        <TextArea rows={4} />
                    </Form.Item>

                    <Form.Item
                        name="cdn"
                        label="CDN"
                        rules={[
                            {
                                required: true,
                                message: '请选择CDN',
                            },
                        ]}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="aliyun" disabled>aliyun</Radio.Button>
                            <Radio.Button value="cfr2">cloudflare</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="estimated_time"
                        label="定时发布"
                    >
                        <DatePicker
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: '请选择计划时间',
                                },
                            ]}
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={disabledDate}
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>

                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '68vh' }}
                pagination={false}
                bordered={true}
                expandable={{
                    expandedRowRender: (record) => {
                        let output = JSON.parse(record.output)
                        return <pre
                            style={{
                                margin: 0,
                            }}
                        >
                            {output.d}
                        </pre>
                    },
                    rowExpandable: (record) => record.status !== 'Not Expandable',
                }}
            />
        </>
    );
}
