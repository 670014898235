import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button, Input, Table, Modal, Tag, Popconfirm, Tooltip, Radio, DatePicker, Space, Select, Tabs, Switch, InputNumber } from 'antd';
import { useParams } from "react-router-dom";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Use404 } from './Use';
import { useSharedValues } from './SharedValueProvider';

const { TextArea } = Input;
const { Option } = Select;

// response.blob().then(blob => {
//     var hiddenElement = document.createElement('a');
//     hiddenElement.href = URL.createObjectURL(blob);
//     hiddenElement.target = '_blank';
//     hiddenElement.download = response.headers.get("Content-Disposition").split("filename=")[1];
//     hiddenElement.click();
// })

const SearchForm = ({ queryList, clickAdd, clickAddResource }) => {
    const [formSearch] = Form.useForm();
    return <Form
        form={formSearch}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        onFinish={queryList}
    >
        <Row gutter={24}>
            <Col xs={14} sm={4} md={6} lg={8} xl={6}>
                <Form.Item name="app_version">
                    <Input placeholder="版本" />
                </Form.Item>
            </Col>
            <Col xs={10} sm={4} md={6} lg={8} xl={2}>
                <Form.Item name="status">
                    <Radio.Group>
                        <Radio value='1'>使用中</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button type="primary" htmlType="submit">搜索</Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={2}>
                <Button onClick={() => {
                    formSearch.resetFields();
                }}
                >
                    清空
                </Button>
            </Col>
            <Col xs={6} sm={4} md={6} lg={8} xl={12} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={clickAdd}>
                    添加版本
                </Button>
                <Button type="primary" onClick={clickAddResource}>
                    添加资源版本
                </Button>
            </Col>
        </Row>
    </Form>
};

//今天及以后
const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
};
export default function Config() {

    let { app } = useParams();
    const { token, makeRequest } = useSharedValues()
    const [ds, setDs] = useState([]);
    const [ld, setLd] = useState(false);
    const [cpt, setCpt] = useState('');
    const p404 = Use404();
    const [connected, setConnected] = useState(false);

    const [store, setStore] = useState([]);
    const [store2, setStore2] = useState([]);

    const [formAdd] = Form.useForm();
    const [formAdd2] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [ga, setGa] = useState({ Id: '1', InternalResourceVersion: null, ForceUpdateGame: false })
    const [beta, setBeta] = useState({ Id: '1', InternalResourceVersion: null, ForceUpdateGame: false })

    const clearGaBeta = () => {
        setGa({ Id: '1' })
        setBeta({ Id: '1' })
    }

    const queryList = useCallback((values, connected) => {
        if (values['status'] === '1') {
            values['status'] = 1
        }
        setLd(true);
        if (connected) {
            const okfn = (d) => {
                setDs(d);
                setConnected(false);
            }
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version`, values, okfn, setLd)
        } else {
            makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version`, values, setDs, setLd)
        }
    }, [app, makeRequest])

    useEffect(() => {
        if (!p404) {
            document.title = `Config`;
            queryList({}, false);
        }
    }, [p404, queryList]);

    useEffect(() => {
        if (!connected) {
            const r = ds.find(r => r.pending || (r.status && r.status === 7));
            if (r) {
                setConnected(true);
                var eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/modp/r/${app}/version/sse/${r.id}?Auth=${token}`);
                eventSource.onopen = function () {
                    console.log('sse connected');
                };
                eventSource.onmessage = function (event) {
                    eventSource.close();
                    queryList({}, true)
                    // setConnected(false)
                    // updateReleaseDate(r.id, event.data)
                };
                eventSource.onerror = function () {
                    console.log('sse closed')
                    eventSource.close();
                    setConnected(false)
                };
            }
        }
    }, [ds])

    if (p404) {
        return p404;
    }

    const clickAdd = () => {
        makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/store`, null, setStore, setLd)

        formAdd.setFieldsValue({
            game_version: '',
            internal_game_version: null,
            // cdn: '',
            estimated_time: null,
            ga: null,
            beta: null,
            // doc: JSON.stringify(JSON.parse(
            //     '{"Ga":{"InternalResourceVersion":null, "ForceUpdateGame": null}, "Beta":{"InternalResourceVersion":null, "ForceUpdateGame": null}}'), null, 4)
        })
        clearGaBeta()
        setStore2([])
        setOpen(true);
    }

    const clickAddResource = () => {
        formAdd2.setFieldsValue({
            doc: ''
        })
        setOpen2(true);
    }

    const okfn = () => {
        setOpen(false);
        setOpen2(false);
        queryList({})
    }

    // type GaBeta struct {
    //     Ga, Beta *struct {
    //         Id                      int64 `json:",omitempty"`
    //         InternalResourceVersion int64 `json:",omitempty"`
    //         ForceUpdateGame         bool  `json:",omitempty"`
    //     }
    // }

    const createVer = (values) => {

        let doc = {}
        if (ga.Id === '1') {
            // doc.Ga = {}
        } else if (ga.Id === '2') {
            doc.Ga = null
        } else if (typeof ga.Id === 'number') {
            doc.Ga = ga
        } else {
            Modal.warning({
                title: 'Id is not a number.',
                content: JSON.stringify(ga),
            });
            return
        }
        if (beta.Id === '1') {
            // doc.Beta = {}
        } else if (beta.Id === '2') {
            doc.Beta = null
        } else if (typeof beta.Id === 'number') {
            doc.Beta = beta
        } else {
            Modal.warning({
                title: 'Id is not a number.',
                content: JSON.stringify(beta),
            });
            return
        }

        values.doc = JSON.stringify(doc)

        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version`, values, okfn, setLd);
    };
    const createResourceVer = (values) => {
        if (values.doc) {
            values = JSON.parse(values.doc)
        } else {
            values = {}
        }
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/resource`, values, okfn, setLd);
    };

    const delOk = (id) => {
        setDs(ds => ds.filter(item => item.id !== id));
    }
    const delfn = (id) => {
        setLd(true);
        makeRequest('DELETE', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/${id}`, null, delOk, setLd)
    }
    const pubfn = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/${r.id}`, r, okfn, setLd)
    }
    const redofn = (r) => {
        setLd(true);
        makeRequest('PUT', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/redo/${r.id}`, r, okfn, setLd)
    }
    const cancelfun = (r) => {
        setLd(true);
        makeRequest('POST', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/cancel/${r.id}`, r, okfn, setLd)
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 56,
        },
        {
            title: '版本',
            width: 52,
            dataIndex: 'game_version',
        },
        {
            title: '内部版本',
            width: 62,
            dataIndex: 'internal_game_version',
        },
        {
            title: '更新内容',
            // dataIndex: 'doc',
            width: 132,
            render: (_, r) => {
                let doc = JSON.parse(r.doc);
                let p = '';
                if (doc.Ga === null) {
                    p += 'Ga -> null'
                } else if (doc.Ga && Object.keys(doc.Ga).length > 0) {
                    p += `Ga -> ${doc.Ga.InternalResourceVersion} ` + (doc.Ga.ForceUpdateGame ? " √" : '')
                }
                if (doc.Beta === null) {
                    if (p.length > 0) {
                        p += '， Beta -> null'
                    } else {
                        p += 'Beta -> null'
                    }
                } else if (doc.Beta && Object.keys(doc.Beta).length > 0) {
                    if (p.length > 0) {
                        p += `， Beta -> ${doc.Beta.InternalResourceVersion}` + (doc.Beta.ForceUpdateGame ? " √" : '')
                    } else {
                        p += `Beta -> ${doc.Beta.InternalResourceVersion}` + (doc.Beta.ForceUpdateGame ? " √" : '')
                    }
                }
                return <>{p}</>
            }
        },
        {
            title: '更新后',
            width: 132,
            // dataIndex: 'doc_revised',
            render: (_, r) => {
                let doc = JSON.parse(r.doc_revised);
                let p = '';

                if (doc.Ga === null) {
                    p += 'Ga -> null'
                } else if (doc.Ga && Object.keys(doc.Ga).length > 0) {
                    p += `Ga -> ${doc.Ga.InternalResourceVersion} ` + (doc.Ga.ForceUpdateGame ? " √" : '')
                }
                if (doc.Beta === null) {
                    p += p ? '， Beta -> null' : 'Beta -> null'
                } else if (doc.Beta && Object.keys(doc.Beta).length > 0) {
                    p += p ? '， ' : ''
                    p += `Beta -> ${doc.Beta.InternalResourceVersion}` + (doc.Beta.ForceUpdateGame ? " √" : '')
                }
                return <>{p}</>
            }
        },
        {
            title: '创建时间',
            width: 128,
            dataIndex: 'create_time',
        },
        {
            title: '发布时间',
            width: 128,
            render: (_, r) => {
                if (r.status === 5 && r.estimated_time) {
                    return <Space direction="vertical" style={{ textAlign: 'left' }}>
                        <span>🕔</span>
                        <span>{r.estimated_time}</span>
                    </Space>;
                } else if (r.release_time) {
                    return r.release_time
                }
            }
        },
        {
            title: '状态',
            width: 98,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0:
                        return <Tag icon={<InfoCircleOutlined />} color="default">新版本</Tag>
                    case 1:
                        if (r.pending) {
                            return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                        }
                        return <Tag icon={<CheckCircleOutlined />} color="success">使用中</Tag>
                    case 2:
                        return <Tag icon={<SyncOutlined spin />} color="processing">发布处理中</Tag>
                    case 3:
                        return <Tag icon={<MinusCircleOutlined />} color="default">历史</Tag>
                    case 4:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">未使用</Tag>
                    case 5:
                        return <Tag icon={<ClockCircleOutlined />} color="default">定时发布</Tag>
                    case 6:
                        return <Tag icon={<MinusCircleOutlined />} color="warning">定时发布取消</Tag>
                    case 7:
                        return <Tag icon={<SyncOutlined spin />} color="processing">处理中</Tag>
                    case 8:
                        return <Tag icon={<ExclamationCircleOutlined />} color="error">处理错误</Tag>
                    default:
                        return <>未知状态</>
                }
            }
        },
        {
            title: '操作',
            width: 204,
            render: (_, r) => {
                if (!r.status) {
                    r.status = 0
                }
                switch (r.status) {
                    case 0: //新建
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要发布此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => pubfn(r)}
                                >
                                    <Button type="dashed">
                                        发布
                                    </Button>
                                </Popconfirm>
                            </Col>
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                            <Col>
                                <Popconfirm title="确定要删除此版本吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => delfn(r.id)}
                                >

                                    <Button type="dashed">
                                        删除
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 1: //发布中
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    case 3: //历史发布
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Tooltip title={cpt} onMouseEnter={() => setCpt('单击复制')}>
                                    <Button type="dashed" onClick={() => {
                                        setCpt('已复制');
                                        navigator.clipboard.writeText(r.doc_revised);
                                    }}>
                                        复制
                                    </Button>
                                </Tooltip>
                            </Col>
                        </Row>
                    case 5: //定时发布
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要取消吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => cancelfun(r)}
                                >
                                    <Button type="dashed">
                                        取消
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    case 8: //处理错误
                        return <Row gutter={8} justify="start">
                            <Col>
                                <Popconfirm title="确定要重试吗？" okText="确定" cancelText="取消"
                                    onConfirm={() => redofn(r)}
                                >
                                    <Button type="dashed">
                                        重试
                                    </Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                    default:
                        return <Row gutter={8} justify="start">
                            {/* <Col>
                                <Progress type="circle" size={30} percent={100} format={() => r.status} />
                            </Col> */}
                        </Row>
                }
            },
        },
    ]

    const Tb = ({ gb, setGb, name }) => {
        const tabch = (k1) => {
            formAdd.resetFields([name])
            if (k1 === "3") {
                setGb({ Id: null, InternalResourceVersion: null, ForceUpdateGame: false })
                return
            } else {
                setGb({ Id: k1, InternalResourceVersion: null, ForceUpdateGame: false })
            }
        }

        const items = [
            {
                key: '1',
                label: '不变',
                children: '{}',
            },
            {
                key: '2',
                label: '清空',
                children: 'null',
            },
            {
                key: '3',
                label: '修改',
                children: <>
                    <Form.Item
                        name={name}
                        label="资源版本"
                        rules={(typeof gb.Id !== 'string') ? [
                            {
                                required: true,
                                message: '请选择资源版本',
                            },
                        ] : []}
                    >
                        <Select
                            defaultValue={gb.Id}
                            onChange={(_, opt) => {
                                setGb((prevState) => ({
                                    ...prevState, Id: opt['value'], InternalResourceVersion: opt['children']
                                }));
                            }}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {store2.map(e => (
                                <Option value={e.K} key={e.V}>{e.V}</Option>
                            ))}
                        </Select>

                    </Form.Item>

                    {/* <Form.Item
                    > */}
                    <Switch onChange={v => {
                        setGb((prevState) => ({
                            ...prevState, ForceUpdateGame: v
                        }));
                    }} checked={gb.ForceUpdateGame} />
                    {/* </Form.Item> */}
                </>,
            },
        ];

        return <Tabs activeKey={(typeof gb.Id !== 'string') ? '3' : gb.Id} items={items} tabPosition="left" onChange={tabch} />

    }


    return (
        <>
            <SearchForm queryList={queryList} clickAdd={clickAdd} clickAddResource={clickAddResource} />

            <Modal
                open={open2}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen2(false);
                }}
                onOk={() => formAdd2.submit()}
            >
                <Form
                    form={formAdd2}
                    layout="vertical"
                    onFinish={createResourceVer}
                >
                    <Form.Item
                        name="doc"
                        label="内容"
                        rules={[
                            {
                                required: true,
                                message: '请输入资源内容',
                            },
                        ]}>
                        <TextArea rows={10} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={open}
                okText="保存"
                cancelText="取消"
                onCancel={() => {
                    setOpen(false);
                }}
                onOk={() => formAdd.submit()}
            >
                <Form
                    form={formAdd}
                    layout="vertical"
                    onFinish={createVer}
                >
                    <Form.Item
                        name="game_version"
                        label="版本"
                        rules={[
                            {
                                required: true,
                                message: '请选择版本',
                            },
                        ]}
                    >

                        <Select
                            showSearch
                            placeholder="选择版本"
                            onChange={v => {
                                clearGaBeta()
                                formAdd.resetFields(['ga', 'beta']);
                                makeRequest('GET', `${process.env.REACT_APP_API_URL}/modp/r/${app}/version/store/${v}`, null, setStore2, setLd)
                            }}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {store.map(e => (
                                <Option value={e} key={e}>{e}</Option>
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item
                        name="internal_game_version"
                        label="内部版本"
                        rules={[
                            {
                                required: true,
                                message: '请输入内部版本',
                            },
                        ]}
                    >

                        <InputNumber
                            min="0"
                            precision={0}
                            step="1"
                        />

                    </Form.Item>

                    <Form.Item
                        label="正式内容"
                    >

                        <Tb gb={ga} setGb={setGa} name="ga" />
                    </Form.Item>

                    <Form.Item
                        label="测试内容"
                    >

                        <Tb gb={beta} setGb={setBeta} name="beta" />
                    </Form.Item>

                    {/* <Form.Item
                        name="cdn"
                        label="CDN"
                        rules={[
                            {
                                required: true,
                                message: '请选择CDN',
                            },
                        ]}
                    >
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="aliyun">aliyun</Radio.Button>
                            <Radio.Button value="cfr2">cloudflare</Radio.Button>
                        </Radio.Group>
                    </Form.Item> */}

                    <Form.Item
                        name="estimated_time"
                        label="定时发布"
                    >
                        <DatePicker
                            disabled
                            rules={[
                                {
                                    required: true,
                                    message: '请选择计划时间',
                                },
                            ]}
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={disabledDate}
                            showTime={{
                                defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>

                </Form>
            </Modal>

            <Table
                locale={{ emptyText: '暂无数据' }}
                columns={columns}
                dataSource={ds}
                rowKey={(r) => r.id}
                loading={ld}
                scroll={{ y: '60vh' }}
                pagination={false}
                bordered={true}
                expandable={{
                    expandedRowRender: (record) => {
                        record.output = record.output ? record.output : "{}"
                        let output = JSON.parse(record.output)
                        return <pre
                            style={{
                                margin: 0,
                            }}
                        >
                            {output.d}
                        </pre>
                    },
                    rowExpandable: (record) => record.status !== 'Not Expandable',
                }}
            />
        </>
    );
}
